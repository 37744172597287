import React from "react";
import styles from "../../styles/info-site/LearnMoreContact.module.css";
import Cta from "../styleguide/Cta";
import TextInfo from "../styleguide/TextInfo";

const LearnMoreContact = ({title, description, ctaLink, ctaText}) => {
    return (
        <div className={styles.LearnMoreContact}>
            <TextInfo title={title} description={description} />
            <Cta className={styles.LearnMoreContactCta} link={ctaLink} text={ctaText} />
        </div>
    );
};

export default LearnMoreContact;