import React from "react";
import LearnMoreStageVideo from "../components/info-site/LearnMoreStageVideo";
import Stage from "../components/info-site/Stage";
import Layout from "../layouts/en";
import Phone from "../assets/phone.png";
import Tablet from "../assets/tablet.png";
import LearnMoreSectionTwoImage from "../assets/learn-more-section-two.png";
import LearnMoreSectionFourImage from "../assets/learn-more-section-four.png";
import LearnMoreSection from "../components/info-site/LearnMoreSection";
import LearnMoreContact from "../components/info-site/LearnMoreContact";
import Seo from "../components/info-site/Seo";

const LearnMore = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="learnMoreTitle" descriptionId="learnMoreDescription" />
            <Stage title="learnMoreStageTitle"
                   description="learnMoreStageDescription"
                   primaryCtaLink="/contact#contact-form"
                   primaryCtaText="learnMoreStagePrimaryCta"
            >
                <LearnMoreStageVideo />
            </Stage>
            <LearnMoreSection background="white" title="learnMoreSectionOneTitle" description="learnMoreSectionOneDescription" ctaLink="/demo/interactive/" ctaText="seeTheDemo" image={Phone} />
            <LearnMoreSection background="gradient" title="learnMoreSectionTwoTitle" description="learnMoreSectionTwoDescription" ctaLink="/demo/interactive/" ctaText="seeTheDemo" image={LearnMoreSectionTwoImage} />
            <LearnMoreSection background="white" title="learnMoreSectionThreeTitle" description="learnMoreSectionThreeDescription" ctaLink="/demo/interactive/" ctaText="seeTheDemo" image={Tablet} />
            <LearnMoreSection background="gradient" title="learnMoreSectionFourTitle" description="learnMoreSectionFourDescription" ctaLink="/demo/interactive/" ctaText="seeTheDemo" image={LearnMoreSectionFourImage} />
            <LearnMoreContact title="learnMoreSectionFiveTitle" description="learnMoreSectionFiveDescription" ctaLink="/contact#contact-form" ctaText="applyToJoin" />
        </Layout>
    );
};

export default LearnMore;