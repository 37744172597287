import React from "react";
import styles from "../../styles/info-site/LearnMoreSection.module.css";
import TextInfo from "../styleguide/TextInfo";

const LearnMoreSection = ({background, title, description, image, ctaLink, ctaText}) => {
    return (
        <div className={`${styles.LearnMoreSection} ${styles[background]}`}>
            <div className={styles.LearnMoreSectionBackground} style={background !== "white" ? {backgroundImage: `url(${image})`} : {}}>
                <div className={styles.LearnMoreSectionPlaceholder}>
                    <div className={styles.LearnMoreSectionImage}>
                        {background === "white" && <img src={image} alt="Learn more" />}
                    </div>
                    <div className={styles.LearnMoreSectionText}>
                        <TextInfo title={title} description={description} />
                        {/* <Cta className={styles.LearnMoreSectionCta} link={ctaLink} text={ctaText} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnMoreSection;