import React from "react";
import styles from "../../styles/info-site/LearnMoreStageVideo.module.css";
import Demo from "./Demo";

const LearnMoreStageVideo = () => {
    return (
        <div className={styles.LearnMoreStageVideo}>
            <Demo />
        </div>
    );
};

export default LearnMoreStageVideo;