import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/info-site/Demo.module.css";

const Demo = () => {
    const [fullScreen, setFullScreen] = useState(false);

    const renderVideo = () => {
        return (
            <video autoPlay={true} muted={true} loop={true}>
                <source src="https://tyviso.com/assets/tyviso-demo-v2.mp4" type="video/mp4" />
                <source src="https://tyviso.com/assets/tyviso-demo-v2.webm" type="video/webm" />
            </video>
        );
    };

    const renderFullScreenVideo = () => {
        return (
            <video controls={true}>
                <source src="https://tyviso.com/assets/tyviso-demo-v2.mp4" type="video/mp4" />
                <source src="https://tyviso.com/assets/tyviso-demo-v2.webm" type="video/webm" />
            </video>
        );
    }

    return (
        <div className={styles.Demo}>
            <span className={styles.DemoLink} onClick={() => setFullScreen(true)}>
                <FormattedMessage id="watchDemo" />
            </span>
            {renderVideo()}
            {
                fullScreen &&
                <div className={styles.DemoFullScreen}>
                    <div className={styles.DemoFullScreenPlaceholder}>
                        <span className={styles.DemoFullScreenClose} onClick={() => setFullScreen(false)}>
                            <FormattedMessage id="closeDemo" />
                        </span>
                        {renderFullScreenVideo()}
                    </div>
                </div>
            }
        </div>
    );
};  

export default Demo;